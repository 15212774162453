import React from 'react';

const Footer = () => {
  return (
    <footer
      className='bg-gray-600 text-white flex justify-center items-center
    text-xs p-3 w-full text-center'
    >
      <span>&copy; Copyright 2021 Angel Valuation. Designed, built and hosted by <a href="https://app.opzoom.com" className="underline hover:opacity-75" target="blank">OpZoom,LLC</a></span>
    </footer>
  );
};

export default Footer;
