import { Link } from 'gatsby';
import React from 'react';

const Navbar = ({ navActive, setNavActive }) => {
  return (
    <nav className='py-2 px-4 lg:px-10 xl:px-40 flex justify-between items-center'>
      <span>
        <Link to='/'>
          <img
            src='/logo.svg'
            alt='Angel Valuation Logo'
            className='h-16 md:h-32'
          />
        </Link>
      </span>
      <button
        className='focus:outline-none xl:hidden absolute z-50 right-4 xl:right-20'
        onClick={() => setNavActive(!navActive)}
      >
        {navActive ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-8 w-8 text-gray-600'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        ) : (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-8 w-8 text-yellow-400'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M4 6h16M4 12h16m-7 6h7'
            />
          </svg>
        )}
      </button>
      <ul className='xl:flex items-center hidden'>
        <li className='mr-6 hover:underline'>
          <Link to='/' activeClassName='underline text-gray-600'>
            {' '}
            Home
          </Link>
        </li>
        <li className='mr-6 hover:underline'>
          <Link to='/about' activeClassName='underline text-gray-600'>
            {' '}
            About
          </Link>
        </li>
        <li className='mr-6 hover:underline'>
          <Link to='/services' activeClassName='underline text-gray-600'>
            {' '}
            Services
          </Link>
        </li>{' '}
        <Link to='/contact'>
          <li className='bg-yellow-300 font-bold px-6 py-3 rounded-full transform transition text-gray-600 hover:scale-95 outline-none'>
            Contact Us
          </li>
        </Link>
      </ul>
    </nav>
  );
};

export default Navbar;
