import { Link } from 'gatsby';
import React, { useState } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({ children }) => {
  const [navActive, setNavActive] = useState(false);
  return (
    <div className='text-gray-600 relative min-h-screen transition-all'>
      {navActive && (
        <div className='fixed right-0 h-full bg-yellow-300 w-1/2 pr-6 pt-24 z-50'>
          <ul className='flex flex-col items-end'>
            <li className='mb-2 font-bold'>
              <Link to='/' activeClassName='text-3xl'>
                {' '}
                Home
              </Link>
            </li>
            <li className='mb-2 font-bold'>
              <Link to='/about' activeClassName='text-3xl'>
                {' '}
                About
              </Link>
            </li>
            <li className='mb-2 font-bold'>
              <Link to='/services' activeClassName='text-3xl'>
                {' '}
                Services
              </Link>
            </li>{' '}
            <li className='mb-2 font-bold'>
              <Link to='/contact' activeClassName='text-3xl'>
                {' '}
                Contact
              </Link>
            </li>{' '}
          </ul>
        </div>
      )}
      <Navbar navActive={navActive} setNavActive={setNavActive} />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
