import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query SiteInfo {
      site {
        siteMetadata {
          description
          title
        }
      }
    }
  `);

  const { title, description } = data.site.siteMetadata;

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta charSet='utf-8' />
      <title>{`${title} | ${siteTitle}`}</title>
      <meta name='description' content={description} />
      <meta property="og:title" content="Angel Valuation" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://angelvaluation.com" />
      <meta property="og:description" content={description} />
      <meta name='robots' content='index, follow' />
      <link rel="shortcut icon" href="/favicons/favicon.ico" type="image/x-icon"/>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png"/>
      <link rel="manifest" href="/favicons/site.webmanifest"/>
    </Helmet>
  );
};

export default SEO;
